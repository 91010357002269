/* Common styles for all screen sizes */
/*  */

/* Modern Navbar Styles */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, #3f0d51 0%, #2a0836 100%);
    padding: 0.7rem 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    backdrop-filter: blur(10px);
}

.navbar-container {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: relative;
    width: 100%;
}

/* Left Section */
.navbar-left {
    flex: 1;
}

.coffee-invitation {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.coffee-icon {
    color: white;
    font-size: 1.5rem;
    animation: bounce 2s infinite;
}

.coffee-text {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    margin: 0;
}

/* Mobile Header */
.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Hamburger Menu */
.hamburger-menu {
    display: none;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 1001;
    transition: all 0.3s ease;
}

.hamburger-menu:hover {
    transform: scale(1.1);
}

/* Content Container */
.navbar-content {
    display: flex;
    flex: 3;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Center Section */
.navbar-center {
    flex: 2;
    display: flex;
    justify-content: center;
    width: 100%;
}

/* Beautiful Email Form - Refined */
.email-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 100%;
    position: relative;
}

.contact-input {
    width: 100%;
    padding: 0.65rem 1.2rem;
    border: none;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.95);
    font-family: 'Roboto', sans-serif;
    font-size: 0.95rem;
    font-weight: 400;
    color: #333;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.contact-input:focus {
    outline: none;
    background: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.contact-input-success {
    background: rgba(236, 253, 245, 0.95);
    box-shadow: 0 4px 15px rgba(16, 185, 129, 0.1);
}

.contact-input-error {
    background: rgba(254, 242, 242, 0.95);
    box-shadow: 0 4px 15px rgba(239, 68, 68, 0.1);
}

.email-error {
    color: #f44336;
    font-size: 0.8rem;
    margin-top: 0.3rem;
    align-self: flex-start;
    padding-left: 1.2rem;
}

.contact-button {
    width: 100%;
    margin-top: 0.6rem;
    padding: 0.65rem 1.2rem;
    border: none;
    border-radius: 50px;
    background: linear-gradient(135deg, #9932cc 0%, #6b1b7e 100%);
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    white-space: nowrap;
    box-shadow: 0 4px 15px rgba(63, 13, 81, 0.2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    letter-spacing: 0.5px;
}

.contact-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #9932cc 0%, #8a2be2 100%);
    z-index: -1;
    transition: opacity 0.3s ease;
    opacity: 0;
    border-radius: 50px;
}

.contact-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(63, 13, 81, 0.3);
}

.contact-button:hover:before {
    opacity: 1;
}

.contact-button:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(63, 13, 81, 0.3);
}

.contact-button.submitting {
    opacity: 0.8;
    cursor: not-allowed;
    transform: scale(0.98);
}

/* Right Section */
.navbar-right {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.contact-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.contact-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    transition: all 0.3s ease;
}

.contact-link:hover {
    color: rgba(255, 255, 255, 0.8);
    transform: translateY(-1px);
}

.contact-icon {
    font-size: 1.25rem;
}

/* Animations */
@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-3px); }
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Responsive Design */
@media screen and (min-width: 769px) {
    .email-form {
        flex-direction: row;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 50px;
        padding: 0.3rem;
        box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
        backdrop-filter: blur(8px);
        border: 1px solid rgba(255, 255, 255, 0.2);
    }
    
    .contact-input {
        width: auto;
        flex: 1;
        box-shadow: none;
    }
    
    .contact-button {
        width: auto;
        margin-top: 0;
        margin-left: 0.4rem;
    }
    
    .navbar-right {
        justify-content: flex-end;
        margin-top: 0;
    }
    
    .contact-links {
        flex-direction: row;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 1200px) {
    .navbar-container {
        padding: 0 1rem;
    }
    
    .coffee-text {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 992px) {
    .navbar-container {
        padding: 0 0.8rem;
    }

    .coffee-text {
        font-size: 1rem;
    }
    
    .contact-input, .contact-button {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        padding: 0.6rem;
    }
    
    .navbar-container {
        padding: 0;
        flex-direction: column;
    }
    
    .hamburger-menu {
        display: block;
    }
    
    .navbar-content {
        display: none;
    }
    
    .navbar-content.mobile {
        width: 100%;
        padding: 1rem;
    }
    
    .navbar-content.mobile.open {
        display: flex;
        flex-direction: column;
        animation: fadeIn 0.3s ease forwards;
    }
    
    .navbar-center, .navbar-right {
        width: 100%;
    }
    
    .email-form {
        width: 100%;
        max-width: 100%;
        padding: 0 1rem;
    }
    
    .contact-input {
        width: 100%;
    }
    
    .contact-button {
        width: 100%;
    }
    
    .mobile-header {
        padding: 0 1rem;
    }
}

@media screen and (max-width: 480px) {
    .navbar {
        padding: 0.5rem 0;
    }
    
    .mobile-header {
        padding: 0 1rem;
    }
    
    .coffee-text {
        font-size: 0.9rem;
    }
    
    .coffee-icon {
        font-size: 1.2rem;
    }
    
    .email-form {
        padding: 0 1rem;
    }
    
    .contact-input {
        padding: 0.6rem 1rem;
    }
    
    .contact-button {
        padding: 0.6rem 1rem;
    }
}

/* High Resolution Screens */
@media screen and (min-width: 1920px) {
    .navbar-container {
        max-width: 2560px;
    }
    
    .coffee-text {
        font-size: 1.5rem;
    }
    
    .contact-input, .contact-button {
        font-size: 1.1rem;
        padding: 0.7rem 1.3rem;
    }
    
    .contact-link {
        font-size: 1.1rem;
    }
}