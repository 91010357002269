.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    overflow-x: hidden;
    position: relative;
}

.blog::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%);
    pointer-events: none;
}

.blog--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
    flex-direction: column;
    position: relative;
}

.blog--header h1 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    position: relative;
    display: inline-block;
}

.blog--header h1::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: currentColor;
    border-radius: 4px;
}

.blog--header p {
    text-align: center;
    line-height: 1.6;
    max-width: 800px;
}

.blog--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog--bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    gap: 6.5rem;
    margin-bottom: 2rem;
}

.blog-Heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    font-size: 13px;
    justify-content: center;
    position: relative;
}

.blog-Heading h2 {
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
}

.blog-Heading h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    border-radius: 2px;
    transform: scaleX(0.7);
    transform-origin: left;
    transition: transform 0.3s ease;
}

.blog-Heading:hover h2::after {
    transform: scaleX(1);
}

.single-blog {
    display: grid;
    align-items: center;
    justify-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    transition: all 0.3s ease;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}

.single-blog::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.single-blog:hover::before {
    opacity: 1;
}

.podcast-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 600px;
}

@media (min-width: 992px) and (max-width: 1380px) {
    .blog--bodyContainer {
        gap: 4rem;
    }
}

@media screen and (max-width: 992px) {
    .blog--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 3rem;
    }
    
    .blog-Heading {
        padding-right: 0;
        justify-content: flex-start;
        width: 100%;
        padding-left: 0px;
        max-width: 600px;
    }
}

@media screen and (max-width: 800px) {
    .blog--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    
    .blog--header p {
        width: 80% !important;
    }
}

@media screen and (max-width: 600px) {
    .blog--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    
    .blog--header p {
        width: 90% !important;
        font-size: 16px !important;
    }
    
    .blog--viewAll {
        position: relative;
        margin-top: 2.5rem;
    }
    
    .blog--viewAll a {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);  
    }
    
    .blog--bodyContainer {
        gap: 2rem;
    }
    
    .podcast-container {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 400px) {
    .blog--header h1 {
        font-size: 2rem;
    }
   .blog--header{
    margin: 0;
   }
    
    .blog--header p {
        font-size: 14px !important;
        margin-top: 0px !important;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}

