/* Modern Experience Section - Clean Slate */
.exp-section {
    min-height: 100vh;
    padding: 80px 0;
    position: relative;
}

.exp-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Header Styling */
.exp-header {
    margin-bottom: 60px;
    text-align: center;
}

.exp-title {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 40px;
    position: relative;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
}

.exp-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: currentColor;
    border-radius: 2px;
}

/* Filter Styling */
.exp-filter-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.exp-filter-group {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

.exp-filter-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 5px;
    transition: transform 0.3s ease;
}

.exp-filter-item:hover {
    transform: translateY(-2px);
}

.exp-filter-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.exp-filter-checkmark {
    height: 22px;
    width: 22px;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: all 0.3s ease;
}

.exp-filter-checkmark::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.exp-filter-item.active .exp-filter-checkmark::after {
    opacity: 1;
}

.exp-filter-label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

/* Timeline Styling */
.exp-timeline {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 25px;
}

/* Card Styling */
.exp-card {
    border-radius: 16px;
    padding: 25px;
    transition: all 0.3s ease;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
}

.exp-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.exp-card-year {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
}

.exp-card-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

.exp-card-image {
    width: 60px;
    height: 60px;
    border-radius: 12px;
    overflow: hidden;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
}

.exp-card-image img {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.exp-card-info {
    flex: 1;
}

.exp-card-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 12px 0;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

.exp-card-category {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

/* Responsive Design */
@media screen and (max-width: 992px) {
    .exp-title {
        font-size: 36px;
    }
    
    .exp-timeline {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .exp-section {
        padding: 60px 0;
    }
    
    .exp-title {
        font-size: 32px;
    }
    
    .exp-filter-group {
        gap: 20px;
    }
    
    .exp-filter-label {
        font-size: 16px;
    }
    
    .exp-timeline {
        grid-template-columns: 1fr;
        max-width: 500px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 576px) {
    .exp-section {
        padding: 50px 0;
    }
    
    .exp-title {
        font-size: 28px;
    }
    
    .exp-filter-group {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    
    .exp-card {
        padding: 20px;
    }
    
    .exp-card-year {
        font-size: 20px;
    }
    
    .exp-card-image {
        width: 50px;
        height: 50px;
    }
    
    .exp-card-title {
        font-size: 16px;
    }
}

/* Ultra-Modern Experience Section */
.experience-wrapper {
    min-height: 100vh;
    position: relative;
    padding: 100px 0;
    overflow: hidden;
}

/* Ambient Glow Effects */
.experience-glow-1 {
    position: absolute;
    top: -200px;
    right: -200px;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(156, 39, 176, 0.2) 0%, rgba(156, 39, 176, 0) 70%);
    filter: blur(80px);
    z-index: 1;
}

.experience-glow-2 {
    position: absolute;
    bottom: -200px;
    left: -200px;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(156, 39, 176, 0.15) 0%, rgba(156, 39, 176, 0) 70%);
    filter: blur(80px);
    z-index: 1;
}

.experience-inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;
    z-index: 2;
}

/* Modern Heading */
.experience-heading {
    text-align: center;
    margin-bottom: 60px;
}

.experience-heading h1 {
    font-size: 3.5rem;
    font-weight: 800;
    letter-spacing: -1px;
    position: relative;
    display: inline-block;
    margin: 0;
    padding-bottom: 15px;
}

.experience-heading h1::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 5px;
    background: linear-gradient(90deg, rgba(156, 39, 176, 0.8), rgba(156, 39, 176, 0.2));
    border-radius: 5px;
}

/* Sleek Tab Design */
.experience-tabs {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 60px;
    flex-wrap: wrap;
}

.experience-tab {
    padding: 12px 25px;
    border-radius: 30px;
    border: 2px solid;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        position: relative;
    overflow: hidden;
    z-index: 1;
}

.experience-tab::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.experience-tab:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.experience-tab:hover::before {
    opacity: 1;
}

.experience-tab.active {
    box-shadow: 0 10px 25px rgba(156, 39, 176, 0.2);
}

/* Ultra-Modern Experience Showcase */
.experience-showcase {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 40px;
}

/* Sleek Experience Item */
.experience-item {
        position: relative;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.experience-item:hover {
    transform: translateY(-10px);
}

.experience-item-year {
    position: absolute;
    top: -15px;
    left: 20px;
    font-size: 2.5rem;
    font-weight: 900;
    z-index: 3;
    text-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    letter-spacing: -1px;
}

.experience-item-content {
    position: relative;
    border-radius: 20px;
    padding: 40px 25px 25px;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    backdrop-filter: blur(10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05), 
                0 1px 3px rgba(0, 0, 0, 0.05), 
                0 -1px 0 rgba(255, 255, 255, 0.1) inset;
    border: 1px solid rgba(255, 255, 255, 0.05);
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.experience-item:hover .experience-item-content {
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1), 
                0 1px 3px rgba(0, 0, 0, 0.05), 
                0 -1px 0 rgba(255, 255, 255, 0.1) inset;
}

.experience-item-glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.experience-item:hover .experience-item-glow {
    opacity: 1;
}

.experience-item-logo {
    width: 70px;
    height: 70px;
    border-radius: 18px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.experience-item-logo img {
    width: 75%;
    height: 75%;
    object-fit: contain;
}

.experience-item-details {
    position: relative;
    z-index: 2;
}

.experience-item-details h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0 0 15px 0;
    line-height: 1.4;
}

.experience-item-tag {
    display: inline-block;
    padding: 6px 15px;
    border-radius: 30px;
    font-size: 0.85rem;
    font-weight: 600;
    color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
    .experience-heading h1 {
        font-size: 3rem;
    }
    
    .experience-showcase {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 30px;
    }
}

@media screen and (max-width: 992px) {
    .experience-wrapper {
        padding: 80px 0;
    }
    
    .experience-heading h1 {
        font-size: 2.8rem;
    }
    
    .experience-item-year {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 768px) {
    .experience-wrapper {
        padding: 60px 0;
    }
    
    .experience-heading h1 {
        font-size: 2.5rem;
    }
    
    .experience-tabs {
        gap: 10px;
        margin-bottom: 50px;
    }
    
    .experience-tab {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
    
    .experience-showcase {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        gap: 25px;
    }
    
    .experience-item-year {
        font-size: 2rem;
        top: -12px;
    }
    
    .experience-item-content {
        padding: 35px 20px 20px;
        min-height: 160px;
    }
    
    .experience-item-logo {
        width: 60px;
        height: 60px;
    }
    
    .experience-item-details h3 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 576px) {
    .experience-wrapper {
        padding: 50px 0;
    }
    
    .experience-heading h1 {
        font-size: 2.2rem;
    }
    
    .experience-heading h1::after {
        width: 80px;
        height: 4px;
    }
    
    .experience-tabs {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .experience-tab {
        margin-bottom: 10px;
        width: calc(50% - 10px);
        text-align: center;
    }
    
    .experience-showcase {
        grid-template-columns: 1fr;
        max-width: 350px;
        margin: 0 auto;
    }
    
    .experience-item-year {
        font-size: 1.8rem;
        top: -10px;
    }
    
    .experience-item-content {
        padding: 30px 20px 20px;
    }
    
    .experience-item-logo {
        width: 55px;
        height: 55px;
    }
    
    .experience-item-details h3 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 400px) {
    .experience-tab {
        width: 100%;
        }
}

/* Modern Timeline Section */
.timeline-section {
    min-height: 100vh;
    padding: 100px 0;
    position: relative;
    overflow: hidden;
}

.timeline-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;
}

/* Header Styling */
.timeline-header {
    text-align: center;
    margin-bottom: 80px;
}

.timeline-header h1 {
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 40px;
        position: relative;
    display: inline-block;
}

.timeline-header h1::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background: currentColor;
        border-radius: 4px;
}

/* Filter Styling */
.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.filter-label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.filter-icon {
    font-size: 1.3rem;
}

.timeline-filters {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 15px 25px;
    border-radius: 15px;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.timeline-filter {
    padding: 10px 20px;
    border-radius: 30px;
    border: 2px solid;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
    user-select: none;
}

.timeline-filter:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.timeline-filter.active {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.filter-check-icon {
    font-size: 1.1rem;
}

/* Timeline Line */
.timeline-line {
    position: relative;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 24px;
}

.timeline-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background-color: inherit;
    border-radius: 2px;
}

/* Timeline Items */
.timeline-item {
    position: relative;
    margin-bottom: 60px;
    width: 50%;
    padding: 0 40px;
    perspective: 1000px;
}

.timeline-item.left {
    left: 0;
}

.timeline-item.right {
    left: 50%;
}

/* Timeline Dot */
.timeline-dot {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border: 4px solid;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.timeline-item.left .timeline-dot {
    right: -30px;
}

.timeline-item.right .timeline-dot {
    left: -30px;
}

.timeline-year {
    font-weight: 700;
    font-size: 1rem;
}

/* Timeline Content */
.timeline-content {
    position: relative;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-left: 4px solid;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    transform-style: preserve-3d;
}

.timeline-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.timeline-item.left .timeline-content {
    border-left: none;
    border-right: 4px solid;
    text-align: right;
}

.timeline-image-container {
    display: inline-block;
    width: 70px;
    height: 70px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.timeline-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.timeline-text h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 15px 0;
    line-height: 1.4;
}

.timeline-category {
    display: inline-block;
    padding: 5px 12px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

/* Animation Enhancements */
@keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7); }
    70% { box-shadow: 0 0 0 10px rgba(255, 255, 255, 0); }
    100% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); }
}

.timeline-dot {
    animation: pulse 2s infinite;
}

/* Responsive Design */
@media screen and (max-width: 992px) {
    .timeline-section {
        padding: 80px 0;
    }
    
    .timeline-header h1 {
        font-size: 3rem;
    }
    
    .timeline-item {
        padding: 0 30px;
    }
}

@media screen and (max-width: 768px) {
    .timeline-section {
        padding: 60px 0;
    }
    
    .timeline-header h1 {
        font-size: 2.5rem;
    }
    
    .timeline-header h1::after {
        width: 80px;
    }
    
    .filter-label {
        font-size: 1rem;
    }
    
    .timeline-filters {
        gap: 10px;
        padding: 12px 20px;
    }
    
    .timeline-filter {
        padding: 8px 15px;
        font-size: 0.9rem;
    }
    
    /* Switch to single column layout */
    .timeline-line::before {
        left: 30px;
    }
    
    .timeline-item {
        width: 100%;
        padding-left: 70px;
        padding-right: 0;
    }
    
    .timeline-item.left, .timeline-item.right {
        left: 0;
    }
    
    .timeline-item.left .timeline-dot, .timeline-item.right .timeline-dot {
        left: 0;
    }
    
    .timeline-item.left .timeline-content {
        border-right: none;
        border-left: 4px solid;
        text-align: left;
    }
    
    .timeline-item.left .timeline-content-inner {
        align-items: flex-start;
    }
    
    .timeline-dot {
        width: 50px;
        height: 50px;
    }
    
    .timeline-year {
        font-size: 1rem;
    }
    
    /* Adjust content layout for mobile */
    .timeline-content-inner {
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }
    
    .timeline-image-container {
        margin-bottom: 0;
        width: 60px;
        height: 60px;
    }
}

@media screen and (max-width: 576px) {
    .timeline-section {
        padding: 50px 0;
    }
    
    .timeline-container {
        padding: 0 15px;
    }
    
    .timeline-header {
        margin-bottom: 30px;
    }
    
    .timeline-header h1 {
        font-size: 2.2rem;
    }
    
    .filter-container {
        width: 100%;
    }
    
    .timeline-filters {
        flex-direction: column;
        width: 100%;
        max-width: 300px;
    }
    
    .timeline-filter {
        width: 100%;
        justify-content: center;
    }

    .timeline-line::before {
        left: 20px;
    }

    .timeline-line {
        padding: 30px 10px;
        border-radius: 12px;
    }
    
    .timeline-item {
        padding-left: 50px;
    }
    
    .timeline-dot {
        width: 40px;
        height: 40px;
    }
    
    .timeline-year {
        font-size: 0.6rem;
    }
    
    .timeline-content {
        padding: 15px;
    }
    
    /* Improved mobile layout with bigger icons */
    .timeline-content-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        width: 100%;
    }
    
    .timeline-image-container {
        width: 70px !important;
        height: 70px !important;
        padding: 8px !important;
        margin-bottom: 0 !important;
        flex-shrink: 0;
        background-color: #fff !important;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
    }
    
    .timeline-text {
        flex: 1;
    }
    
    .timeline-text h3 {
        font-size: 1rem;
        margin-bottom: 10px;
    }
    
    .timeline-category {
        font-size: 0.75rem;
        padding: 4px 10px;
    }
}

/* For very small screens */
@media screen and (max-width: 400px) {
    .timeline-image-container {
        width: 60px !important;
        height: 60px !important;
    }
    
    .timeline-content {
        padding: 12px;
    }
    
    .timeline-content-inner {
        gap: 12px;
    }
    
    .timeline-text h3 {
        font-size: 0.95rem;
    }
}

/* Add these styles for the link */
.timeline-link {
    text-decoration: none;
    position: relative;
    display: inline-block;
    transition: all 0.2s ease;
}

.timeline-link:hover {
    transform: translateY(-2px);
}

.timeline-link h3 {
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.link-icon {
    font-size: 0.8em;
    opacity: 0.7;
    transition: all 0.2s ease;
}

.timeline-link:hover .link-icon {
    opacity: 1;
    transform: translateX(2px) translateY(-2px);
}

/* Add this new wrapper class for better mobile layout */
.timeline-content-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.timeline-item.left .timeline-content-inner {
    align-items: flex-start
}













