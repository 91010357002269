/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/*
* Premium CEO Portfolio Hero Section
* Modern, Clean & Aesthetic Design
*/

.hero-body {
 margin: 0;
  background-color: #3f0d51;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Premium background effects */
.hero-background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 30% 50%, rgba(107, 27, 126, 0.4) 0%, rgba(63, 13, 81, 0) 50%),
    radial-gradient(circle at 70% 20%, rgba(153, 50, 204, 0.2) 0%, rgba(63, 13, 81, 0) 60%);
  z-index: 1;
}

.hero-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 0.5%),
    radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 0.5%),
    radial-gradient(circle at 80% 40%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 0.5%),
    radial-gradient(circle at 10% 60%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 0.5%),
    radial-gradient(circle at 90% 10%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 0.5%),
    radial-gradient(circle at 30% 90%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 0.5%),
    radial-gradient(circle at 70% 70%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 0.5%),
    radial-gradient(circle at 40% 10%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 0.5%);
  background-size: 
    120px 120px,
    150px 150px,
    100px 100px,
    200px 200px,
    180px 180px,
    120px 120px,
    160px 160px,
    140px 140px;
  background-repeat: repeat;
  opacity: 0.4;
  z-index: 1;
}

.hero-light-beam {
  position: absolute;
  top: -100px;
  right: -100px;
  width: 300px;
  height: 1000px;
  background: linear-gradient(135deg, rgba(153, 50, 204, 0.1) 0%, rgba(63, 13, 81, 0) 60%);
  transform: rotate(45deg);
  filter: blur(40px);
  z-index: 1;
  animation: beam-move 15s infinite alternate ease-in-out;
}

@keyframes beam-move {
  0% { transform: rotate(45deg) translateX(0); }
  100% { transform: rotate(45deg) translateX(-200px); }
}

/* Executive layout wrapper */
.hero-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  z-index: 2;
}

/* Premium text section styling */
.hero-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
          justify-content: center;
  max-width: 550px;
  margin-right: 60px;
  z-index: 5;
}

.hero-eyebrow {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-family: 'Roboto', sans-serif;
  position: relative;
  padding-left: 30px;
}

.hero-eyebrow::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 2px;
  background: linear-gradient(90deg, #9932cc, rgba(153, 50, 204, 0.5));
  transform: translateY(-50%);
}

.hero-title {
  color: white;
  font-size: 3.2rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  letter-spacing: -0.5px;
  text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  user-select: none;
  position: relative;
}

.hero-subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 2.5rem;
  font-family: 'Roboto', sans-serif;
  max-width: 90%;
}

.hero-cta-button {
  background: rgba(255, 255, 255, 0.08);
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 1.1rem 2.2rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;  
  gap: 12px;
  position: relative;
  overflow: hidden;
}

.hero-cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(153, 50, 204, 0.8) 0%, rgba(107, 27, 126, 0.8) 100%);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.hero-cta-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
}

.hero-cta-button:hover::before {
  opacity: 1;
}

.hero-cta-button:active {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.button-icon {
  font-size: 1.4rem;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

/* Enhanced video container styling */
.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  }

  .landing--vid {
  width: 450px;
  height: 450px;
  aspect-ratio: auto 450 / 450;
    position: relative;
  filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.4));
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.landing--vid.playing {
  transform: scale(1.05);
}

/* Premium glow effect */
.video-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 120%;
    border-radius: 50%;
  background: radial-gradient(circle, rgba(153, 50, 204, 0.3) 0%, rgba(63, 13, 81, 0) 70%);
  z-index: -1;
  filter: blur(30px);
  animation: glow 6s infinite alternate;
}

@keyframes glow {
  0% { opacity: 0.6; transform: translate(-50%, -50%) scale(1); }
  100% { opacity: 1; transform: translate(-50%, -50%) scale(1.1); }
}

.video-container {
  width: 100%;
  height: 100%;
    display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 100%;
    position: relative;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 0 0 10px rgba(153, 50, 204, 0.05),
    inset 0 0 30px rgba(0, 0, 0, 0.2);
  }

  .landing--vid video {
    display: flex;
  width: 100%;
  height: 100%;
       object-fit: contain;
    overflow: hidden;
  border-radius: 100%;
    border: none;
  outline: none;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, rgba(63, 13, 81, 0.3) 0%, rgba(63, 13, 81, 0.7) 100%);
  border-radius: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.landing--vid:hover .video-overlay {
  opacity: 1;
}

.play-indicator {
  font-size: 3rem;
  color: white;
  opacity: 0.8;
  animation: float 2s infinite ease-in-out;
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
  }

  .random-text {
    position: absolute;
    top: 31%;
    left: 33%;
  width: 35%;
            transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 
    0 0 10px rgba(255, 255, 255, 0.5),
      0 0 20px rgba(255, 255, 255, 0.4),
      0 0 30px rgba(255, 255, 255, 0.3),
    0 0 40px rgba(255, 255, 255, 0.2),
    0 0 50px rgba(255, 255, 255, 0.2);
    z-index: 1;
  padding: 10px;
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

/* Enhanced scroll indicator */
.custom-hero {
  position: absolute;
  bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.scroll-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.scroll-link:hover {
  transform: translateY(-5px);
}

.custom-button {
  width: 38px;
  height: 70px;
  border-radius: 38px;
  border: 3px solid rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.custom-button:hover {
  border-color: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.1);
}

.custom-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 4px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.scroll-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Media queries for responsive design */
@media screen and (max-width: 1400px) {
  .hero-title {
    font-size: 2.8rem;
  }
  
  .landing--vid {
    width: 420px;
    height: 420px;
  }
  
  .hero-subtitle {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1200px) {
  .hero-content-wrapper {
    padding: 0 30px;
  }
  
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 1rem;
  }
  
  .hero-cta-button {
    font-size: 1.1rem;
    padding: 0.9rem 1.8rem;
  }
  
  .landing--vid {
    width: 380px;
    height: 380px;
  }
  
  .hero-text-section {
    margin-right: 40px;
  }
}

@media screen and (max-width: 992px) {
  .hero-content-wrapper {
    padding: 0 25px;
  }
  
  .hero-title {
    font-size: 2.2rem;
    margin-bottom: 1.2rem;
  }
  
  .hero-subtitle {
    margin-bottom: 2rem;
  }
  
  .hero-text-section {
    margin-right: 30px;
  }
  
  .landing--vid {
    width: 350px;
    height: 350px;
  }
  
  .random-text {
    font-size: 16px;
  }
  
  .hero-eyebrow {
    font-size: 0.8rem;
    margin-bottom: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-content-wrapper {
    flex-direction: column;
    justify-content: center;
    padding: 80px 20px 0;
  }
  
  .hero-text-section {
    align-items: center;
    text-align: center;
    margin-right: 0;
    margin-bottom: 50px;
    max-width: 100%;
  }
  
  .hero-title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.2rem;
  }
  
  .hero-subtitle {
    text-align: center;
    max-width: 100%;
  }
  
  .hero-eyebrow {
    padding-left: 0;
  }
  
  .hero-eyebrow::before {
    display: none;
  }
  
  .landing--vid {
    width: 320px;
    height: 320px;
  }
  
  .custom-hero {
    bottom: 20px;
  }
}

@media screen and (max-width: 576px) {
  .hero-content-wrapper {
    padding: 60px 15px 0;
  }
  
  .hero-text-section {
  margin-bottom: 40px;
}
  
  .hero-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 0.95rem;
    margin-bottom: 1.8rem;
  }
  
  .hero-cta-button {
    font-size: 1rem;
    padding: 0.8rem 1.6rem;
  }
  
  .landing--vid {
    width: 280px;
    height: 280px;
  }
  
  .random-text {
    font-size: 14px;
    width: 50%;
  }
  
  .custom-hero {
    bottom: 15px;
  }
  
  .custom-button {
    width: 34px;
    height: 60px;
  }
  
  .scroll-text {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 1.6rem;
  }
  
  .hero-container {
    align-items: center;
    justify-content: center;
  }
  
  .hero-cta-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.4rem;
  }
  
  .landing--vid {
    width: 260px;
    height: 260px;
  }
  
  .play-indicator {
    font-size: 2.5rem;
  }
}

/* High-end device optimizations */
@media screen and (min-width: 1800px) {
  .hero-content-wrapper {
    max-width: 1800px;
  }
  
  .hero-title {
    font-size: 3.8rem;
  }
  
  .hero-subtitle {
    font-size: 1.4rem;
  }
  
  .hero-cta-button {
    font-size: 1.4rem;
    padding: 1.2rem 2.4rem;
  }
  
  .landing--vid {
    width: 500px;
    height: 500px;
  }

  .random-text {
    font-size: 20px;
  }
  
  .hero-eyebrow {
    font-size: 1rem;
  }
}