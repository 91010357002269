.podcast2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    width: 100%;
    position: relative;
    padding-top: 1rem;
}

.podcast2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0) 100%);
    pointer-events: none;
    opacity: 0.5;
}

.podcast2--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.podcast2--bodyContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
    gap: 6.5rem;
}

.podcast2-Heading {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    font-size: 13px;
    width:100%;
    white-space: nowrap;
    position: relative;
}

.podcast2-Heading h2 {
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
    font-size: 1.5rem;
    letter-spacing: 0.5px;
}

.podcast2-Heading h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    border-radius: 2px;
    transform: scaleX(0.7);
    transform-origin: left;
    transition: transform 0.3s ease;
}

.podcast2-Heading:hover h2::after {
    transform: scaleX(1);
}

.podcast2-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 600px;
}

.single-podcast2 {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
}

.single-podcast2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.single-podcast2:hover::before {
    opacity: 1;
}

.podcast2-wrapper {
    position: relative;
    z-index: 0;
}

.podcast2-wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, rgba(130,58,224,0.7) 0%, rgba(130,58,224,0.3) 100%);
    z-index: 2;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease;
}

.single-podcast2:hover .podcast2-wrapper::after {
    transform: scaleX(1);
}

@media (min-width: 992px) and (max-width: 1380px) {
    .podcast2--bodyContainer {
        gap: 4rem;
    }
}

@media screen and (max-width: 992px) {
    .podcast2--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 3rem;
    }
    
    .podcast2-Heading {
        padding-right: 0;
        justify-content: flex-start;
        width: 100%;
        padding-left: 0px;
        max-width: 600px;
    }
}

@media screen and (max-width: 800px) {
    .podcast2-Heading h2 {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    .podcast2--bodyContainer {
        gap: 2rem;
    }
    
    .podcast2-container {
        gap: 1.5rem;
    }
}

@media only screen and (max-width: 480px) {
    .podcast2-Heading {
        font-size: 13px;
        padding-right: 30%;
    }
    
    .podcast2-Heading h2 {
        font-size: 1.2rem;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}

@media only screen and (device-width: 768px) {

}